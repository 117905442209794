import {AddCircle, Cancel, DeleteOutlined, Save} from "@mui/icons-material";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import {useSnackbar} from "notistack";
import React, {useContext, useEffect, useState} from "react";
import {callApi, saveApi, UserContext} from "../App";
import {MainContext} from "../Main";
import {fetchServerInfo} from "./Ec2";

export default function SecurityGroupListDialogV2(props) {
    const user = useContext(UserContext)
    const context = useContext(MainContext)
    const { enqueueSnackbar } = useSnackbar();
    const [list, setList] = useState([])
    const [changes, setChanges] = useState({filters:[] , securityGroups: []});

    
    useEffect(() => {
        if (list.length > 0) return;
        console.debug("[SecurityGroupListDialogV2.useEffect] instances : ", props.instances)

        callApi(`/ec2/securityGroup/list?profile=${props.instances[0].profile}&region=${props.instances[0].region}`,
        (list) => setList(list.sort((a, b) => a.name.localeCompare(b.name)))
            , null, (msg) => enqueueSnackbar(msg.msg, {variant: msg.severity}))
    }, [props.instances])

    useEffect(() => {
        const idList = [];
        const sgCounts = {};
        const sgList = [];

        props.instances.forEach((instance) => {
            instance.securityGroup.forEach((sg) => {
                sgCounts[sg.id] = (sgCounts[sg.id] || 0) + 1;
                // server 들에 공통으로 적용된 목록만 
                if (sgCounts[sg.id] === props.instances.length) {
                    idList.push(sg.id);
                    sgList.push(sg)
                }
            })
        });
        setChanges({filters: idList, securityGroups: sgList, original: idList});
    },[props.instances])

    return (
        <Dialog open={true}>
            <DialogTitle>보안 그룹 목록</DialogTitle>

            <DialogContent>
                <Autocomplete renderInput={(params) => (
                    <TextField
                        {...params}
                        label="보안그룹 이름"
                    />
                )}  options={list.filter(sg => !changes.filters.includes(sg.id))} getOptionKey={(sg) => sg.id}
                    getOptionLabel={(sg) => sg.name} onChange={handleOptionSelect} 
                />

                <Table>
                    <TableBody>
                        {changes.securityGroups.map(sg => {
                            return (
                                <TableRow>
                                    <TableCell>{sg.id}</TableCell>
                                    <TableCell>{sg.name}</TableCell>
                                    <TableCell>{sg.desc}</TableCell>
                                    <TableCell>
                                        <Tooltip title="보안 그룹 규칙을 삭제합니다." placement="top">
                                            <DeleteOutlined onClick={() => deleteSG(sg)} />
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </DialogContent>

            <DialogActions>
                {user.authority === 'ADMIN' && 
                <Tooltip title="새로운 Security Gropu을 생성합니다." placement="top">
                    <Button  variant="outlined" startIcon={<AddCircle />} color="primary" onClick={createSecurityGroup}>
                        생성
                    </Button>
                </Tooltip>}
                <Tooltip title="추가/삭제 한 보안 그룹을 서버에 적용합니다." placement="top">
                    <Button variant="contained" startIcon={<Save />} color="primary" onClick={saveSecurityGroup}>
                        저장
                    </Button>
                </Tooltip>
                <Tooltip title="작업 내용을 버리고, 팝업 창을 닫습니다." placement="top">
                    <Button variant="outlined" startIcon={<Cancel />} color="secondary" onClick={closeDialog}>
                        닫기
                    </Button>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );

    /**
     * 옵션 목록에서 선택한 Security Group을 목록에 추가 
     * 
     * @param {Event} event 
     * @param {Object} selectedSG 선택한 SecurityGroup 
     */
    function handleOptionSelect(event, selectedSG) {
        if (selectedSG) {
            console.debug(`Selected option: ${selectedSG.id}`);

            if (changes.securityGroups.some(sg => sg.id === selectedSG.id)) {
                enqueueSnackbar(`${selectedSG.name} - 이미 등록된 보안 그룹 입니다.`, { variant: "warning" })
            } else { // 보안 그룹 목록에 추가 한다.
                const securityGroups = [...changes.securityGroups]
                securityGroups.push(selectedSG)
                setChanges({...changes, securityGroups: securityGroups})
                // const newFilters = [...changes.filters, selectedSG.id]
                // setChanges({...changes, filters: newFilters, securityGroups: securityGroups}) // 없는 값이 되어 Autocomplete 에러 
            }
        }
    }

    function closeDialog(){
        if( !changes.securityGroups.some(sg => !changes.original.includes(sg.id)) 
            || window.confirm("팝업을 닫으면 변경한 내용이 사라집니다. 그래도 닫으시겠습니까?") )
            props.setSecurityGroup({})
    }


    function createSecurityGroup(){
        const name = window.prompt("보안 그룹 이름을 입력하세요.", "")
        const instance = props.instances[0]
        const data = {profile: instance.profile, name: name, vpcId: instance.vpcId, description: `create by ${user.name}`, region: instance.region}
        saveApi(`/ec2/securityGroup/create`, "POST", data, (result) => {
            console.debug(`create security group result : ${result}`)
            enqueueSnackbar(`'${instance.name}' 환경에 보안 그룹 '${data.name}'을  생성했습니다.`, { variant: "error" })
            const securityGroups = [...changes.securityGroups, {...data, id: result.groupId}]
            setChanges({...changes, securityGroups: securityGroups})
        }, (error) => {
            console.error(`request data : ${data}`)
            enqueueSnackbar(`'${instance.name}'보안 그룹 생성을 실패했습니다.\n${error.msg}`, { variant: "error" })
        })
    }

    /**
     * 해당 security group을 목록에서 삭제 한다. 
     * 
     * @param {Object} selectedSG 
     */
    function deleteSG(selectedSG) {
        const securityGroups = [...changes.securityGroups.filter(sg => sg.id !== selectedSG.id)]
        const filters = [...changes.filters.filter(sg => sg.id !== selectedSG.id)]

        setChanges({...changes, filters: filters, securityGroups: securityGroups})
    }

    /**
     * 수정한 보안 그룹 목록을 저장 한다. 
     */
    function saveSecurityGroup() {
        // 서버마다 목록을 정리해서 처리 요청을 한다. 
        props.instances.forEach((instance) => {
            // 기존 sg id 목록, 다른 서버와 공통으로 있던 목록은 제외 
            const exist = instance.securityGroup.filter(sg => !changes.original.includes(sg.id))
                        .map(({ id, name }) => ({ id, name }))
            // 새로운 목록을 만들어서 data로 
            const data = changes.securityGroups.map(({ id, name }) => ({ id, name })).concat(exist)
            const uri = `/ec2/${instance.id}/securityGroups/replace?profile=${instance.profile}&region=${instance.region}`
            saveApi(uri, "PUT", data, (result) => {
                console.debug(`save security group result : ${result}`)
                if (result){
                    enqueueSnackbar(`'${instance.name}'서버에 보안 그룹 목록을 적용 했습니다.`, { variant: "success" })
                    fetchServerInfo(instance, () => { }, context)
                    const original = [...changes.securityGroups.map(sg => sg.id)]
                    setChanges({...changes, original: original})
                }else{
                    enqueueSnackbar(`'${instance.name}'서버에 보안 그룹 목록을 적용을 실패했습니다.`, { variant: "error" })
                }
            }, (msg) => { enqueueSnackbar(msg.msg, { variant: msg.severity }) })
        })
    }
}