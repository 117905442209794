import {ArchiveOutlined, ContentCopy, ModeEdit, ReplayOutlined, UnarchiveOutlined} from "@mui/icons-material";
import {
    Button,
    Checkbox,
    Container,
    Dialog,
    FormControl,
    Input,
    InputLabel,
    Link,
    Menu,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {useSnackbar} from "notistack";
import React, {useContext, useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {callApi, deleteApi, saveApi, UserContext} from "../App";
import {MainContext} from "../Main";
import {checkFilter, fetchServerInfo, getEndpointStatusIcon, groupCheck, powerIcon} from "./Ec2";
import Ec2SecurityGroupEditorV2 from "./Ec2SecurityGroupEditorV2";
import {openPage} from "./Ec2Toolbar";
import SecurityGroupListDialogV2 from "./SecurityGroupListDialogV2";
import Ec2ToolbarV2 from "./Ec2ToolbarV2";
import * as PropTypes from "prop-types";

const DEF_PROFILE = "default";
const HC_TITLE = "HC-Endpiont";

class Ec2TableHead extends React.Component {
    render() {
        return <TableRow className={"bg-light"}>
            <TableCell width={10}> <Checkbox onChange={this.props.onChange} /> </TableCell>
            <TableCell width={10}>#</TableCell>
            {this.props.tags.filter(this.props.predicate).map(this.props.getCellFn)}
            {this.props.properties.filter(this.props.predicate).map(this.props.getSGCellFn)}
        </TableRow>;
    }
}

Ec2TableHead.propTypes = {
    onChange: PropTypes.func,
    tags: PropTypes.arrayOf(PropTypes.any),
    predicate: PropTypes.func,
    getCellFn: PropTypes.func,
    properties: PropTypes.any,
    getSGCellFn: PropTypes.func
};

function getTagCell(item, showEditor, setServerState) {
    if (item.key === "Name")
        return <FormControl variant="outlined" size="small" style={{ marginLeft: 10, minWidth: 120 }}>
            <InputLabel>서버</InputLabel>
            <Select small label="서버" onChange={(e) => setServerState(e.target.value)}>
                <MenuItem value={""}>All</MenuItem>
                <MenuItem value={"running"}>Running</MenuItem>
                <MenuItem value={"stopped"}>Stopped</MenuItem>
            </Select>
        </FormControl>
    return <TableCell key={item.key}>
        <Tooltip title={`선택한 서버들의 '${item.label}' 태그를 일괄 변경합니다.`} placement="top">
            <Link href="#" onClick={() => showEditor(item.key)}>{item.label}</Link>
        </Tooltip>
    </TableCell>;
}

function getSecurityGroupCell(user, item, editSecurityGroup) {
    if (user.authority === "ADMIN" && item.key === "securityGroup")
        return <TableCell key={item.key}>
            <Tooltip title={`선택한 서버(들)의 '${item.label}'을 변경합니다.`} placement="top">
                <Link href="#" onClick={() => editSecurityGroup()}>{item.label}</Link>
            </Tooltip></TableCell>
    else
        return <TableCell key={item.key}>{item.label}</TableCell>
}

class Ec2TableBody extends React.Component {
    render() {
        return <>
            {this.props.context.servers.filter(this.props.groupFilter)
                .filter(this.props.serverFilter) // 검색
                .map(this.props.getRowFn
                )
            }
        </>;
    }
}

Ec2TableBody.propTypes = {
    context: PropTypes.shape({ servers: PropTypes.any }),
    groupFilter: PropTypes.func,
    serverFilter: PropTypes.func,
    getRowFn: PropTypes.func
};

function getTableRow(server, selection, changeSelection, index, tags, getProp, properties) {
    return <TableRow key={server.id} sx={{ '&:last-child td': { border: 0 } }}>
        <TableCell>
            <Checkbox
                checked={selection[server.id] === undefined ? false : selection[server.id]}
                onChange={(e) => changeSelection(e, server.id)} />
        </TableCell>
        <TableCell>{index + 1}</TableCell>
        {/* TAG 목록 출력 */}
        {tags.filter(t => t.checked).map(tag =>
            <TableCell key={tag.key} style={{ whiteSpace: 'nowrap' }}>
                {getProp(server.tags, tag.key, server)}
                {server.accessLevel > 30 ?
                    <TagEditor tagKey={tag.key} server={server} /> : ""}
            </TableCell>)}
        {/* 속성 목록 출력 */}
        {properties.filter(p => p.checked).map(prop =>
            <TableCell key={prop.key} style={{ whiteSpace: 'nowrap' }}>
                {getProp(server, prop.key, server)}
            </TableCell>)}
    </TableRow>;
}

/**
 * Main Function
 *
 * @returns {Element}
 * @constructor
 */


export default function Ec2Admin() {
    const user = useContext(UserContext)
    const [cookies, setCookie] = useCookies([]);
    const { enqueueSnackbar } = useSnackbar();
    const context = useContext(MainContext)
    // aws 에서 제공하는 속성, 수정은 안돼요
    const [properties, setProperties] = useState([
        { key: "id", label: "ID", checked: false },
        { key: "securityGroup", label: "방화벽", checked: false },
        { key: "ip", label: "IP(public/private)", checked: true },
        { key: "profile", label: "Profile", checked: false },
        { key: "keyName", label: "SSH Key", checked: false },
        { key: "type", label: "EC2 Type", checked: false },
        { key: "launchTime", label: "시작 시간", checked: false },
        { key: "publicDNSName", label: "Public DNS", checked: false },
        { key: "platform", label: "Platform", checked: false },
        { key: "architecture", label: "Architecture", checked: false },
        { key: "imageId", label: "Image Id", checked: false },
        { key: "subnetId", label: "Subnet Id", checked: false },
        { key: "vpcId", label: "VPC ID", checked: false },
        { key: "blockDevice", label: "Disk", checked: false },
        { key: "state", label: "State", checked: false },
    ]);
    const [tags, setTags] = useState([])
    const [serverName, setServerName] = useState("")
    const [editDialog, setEditDialog] = useState({ open: false })
    const [selection, setSelection] = useState({})
    const [securityGroup, setSecurityGroup] = useState({})
    const [serverState, setServerState] = useState("")

    useEffect(() => {
        callApi("/tags/list", (tagList) => {
            setProperties(applySavedValue(cookies["props"], properties))
            setTags(applySavedValue(cookies["tags"], tagList))
        }, null, (msg) => enqueueSnackbar(msg.msg, { variant: msg.severity }))
        callApi("/ec2/instances/updateAccessLevel", (result) => {
            if (result.isUpdate === true)
                enqueueSnackbar("서버 접근 권한이 업데이트 되었습니다. 서버 목록을 재조회 하세요!!!", { variant: "success", autoHideDuration: 10000 })
        }, null, (msg) => enqueueSnackbar(msg.msg, { variant: msg.severity }))
    }, []);

    return (<>
        <Ec2ToolbarV2 filter={context.keyword} setFilter={context.setKeyword} serverName={serverName}
            setServerName={setServerName} tags={tags}
            properties={properties} changePropSelect={changePropSelect} changeTagSelect={changeTagSelect}
            reload={reload}
            terminateServer={terminateServer} />
        <div className="row px-3"></div>
        {user.authority === "ADMIN" && <div className="row py-1"></div>}
        <div className="row px-3 py-5">
            <Table sx={{ overflowX: 'auto' }} size="small" aria-label="server list table">
                <TableHead>
                    <Ec2TableHead onChange={changeAllSelection} tags={tags} predicate={item => item.checked}
                        getCellFn={item => getTagCell(item, showEditor, setServerState)} properties={properties}
                        getSGCellFn={item => getSecurityGroupCell(user, item, editSecurityGroup)} />
                </TableHead>
                <TableBody>
                    <Ec2TableBody context={context}
                        groupFilter={(row) => context.keyword === "" || groupCheck(row.group, context.keyword)}
                        serverFilter={(row) => checkFilter(row, serverName, serverState)}
                        getRowFn={(server, index) =>
                            getTableRow(server, selection, changeSelection, index, tags, getProp, properties)} />
                </TableBody>
            </Table>
            {/* tag 일괄 변경 에디터 */}
            {editDialog.open &&
                <Dialog open={true} onClose={() => setEditDialog({ ...editDialog, open: false })}>
                    <Container sx={{ width: '500px' }} className="row">
                        일괄 변경 Tag : {editDialog.key} <br />
                        변경 대상 서버
                        : {Object.keys(selection).filter(k => selection[k]).map(k => context.servers.filter(s => s.id === k)[0].name).join(", ")}
                        <Input aria-label={editDialog.key} multiline placeholder={editDialog.key}
                            value={editDialog.value} sx={{ width: '100%' }}
                            onChange={(e) => setEditDialog({ ...editDialog, value: e.target.value })} />

                        <Button onClick={changeTag4Servers}
                            disabled={editDialog.value === undefined || editDialog.value === ""}>Save</Button>
                    </Container>
                </Dialog>}

            {securityGroup.id !== undefined && securityGroup.manageSecurityGroup === false &&
                <Ec2SecurityGroupEditorV2 securityGroup={securityGroup} setSecurityGroup={setSecurityGroup} />}
            {securityGroup.manageSecurityGroup &&
                <SecurityGroupListDialogV2 instances={securityGroup.instances} setSecurityGroup={setSecurityGroup} />}
        </div>
    </>);



    function editSecurityGroup(server, sgId) {
        if (sgId === undefined && Object.values(selection).length === 0) {
            enqueueSnackbar("서버를 선택 하세요 ", { variant: "warning" })
            return
        }

        if (sgId === undefined) {
            const list = context.servers.filter(s => selection[s.id])
            if (list.some((instance) => instance.profile !== list[0].profile)) {
                enqueueSnackbar("서로 다른 profile의 서버들을 동시에 설정할 수 없습니다.", { variant: "error" })
                return
            }

            setSecurityGroup({ instances: list, manageSecurityGroup: true })
        } else {
            setSecurityGroup({ instance: server, id: sgId, manageSecurityGroup: false })
        }
    }

    /**
     * 
     * @param {Instance} server 
     * @param {String} sgId 
     */
    function showSecurityGroup(server, sgId) {
        setSecurityGroup({ instance: server, id: sgId, readOnly: true, manageSecurityGroup: false })
    }

    /**
     * cookie에서 읽은 props 값이 있으면, defaultValue의 checked 값을 적용해 준다.
     * @param {Array} props cookie에서 읽은 properties, tags 값 목록
     * @param {*} defaultValue properties, tags 기본 선택 설정된 값 객체
     * @returns props값이 반영된 defaultValue
     */
    function applySavedValue(props, defaultValue) {
        if (props) {
            console.debug("[Ec2Admin.applySavedValue] props : ", props)
            defaultValue.forEach(prop => {
                const find = props.filter(t => t.key === prop.key)
                prop.checked = find.length > 0 ? find[0].checked : false
            })
        }
        return defaultValue;
    }


    /**
     * 선택한 서버들의 tag를 일괄적으로 변경한다. 
     * - 새로 지정한 값이 있는 경우만 처리 
     */
    function changeTag4Servers() {
        if (editDialog.value === undefined || editDialog.value === "") return

        console.debug("[Ec2Admin.changeTag4Servers] all change tag key : ", editDialog.key, editDialog.value)
        // 서버 목록 만들기 
        const servers = context.servers.filter(s => selection[s.id])
        console.debug("[Ec2Admin.changeTag4Servers] server list : ", servers)

        let count = 0;
        enqueueSnackbar(`${servers.length}개 서버의 태그 변경을 요청합니다.`, { variant: "info", autoHideDuration: 2000 })
        // servers 수 만큼 호출 
        servers.forEach((server, index) => {
            saveApi(`/ec2/${server.id}/tag/${editDialog.key}/change`, "PUT",
                { value: editDialog.value, profile: server.profile, gitlab: server.gitlab, region: server.region }, (result) => {
                    console.debug("[Ec2Admin.saveTag] tag change all : ", result)
                    fetchServerInfo(server, () => { }, context)
                    count++
                    if (index === servers.length - 1)
                        enqueueSnackbar(`${count}개 서버의 '${editDialog.key}' 태그를 변경 했습니다.`, { variant: "success", autoHideDuration: 2000 })
                }, (msg) => {
                    enqueueSnackbar(`${server.name} 서버의 '${editDialog.key}' 태그 변경을 실패했습니다.\n ${msg.msg}`, { variant: "error", autoHideDuration: 5000 })
                }, (msg) => { enqueueSnackbar(msg.msg, { variant: "error" }) })
        })

    }

    /**
     * 전체 선택 checkbox 처리 
     * 
     * @param {Event} event 전체 선택 checkbox 클릭 이벤트 
     */
    function changeAllSelection(event) {
        if (!event.target.checked) {
            setSelection({})
            return;
        }
        const idToObjectMap = context.servers
            .filter((row) => context.keyword === "" || groupCheck(row.group, context.keyword))
            .filter((row) => checkFilter(row, serverName, ""))
            .reduce((acc, object) => {
                acc[object.id] = event.target.checked;
                return acc;
            }, {});
        setSelection(idToObjectMap)
    }

    /**
     * 개별 서버 선택 변경 
     * 
     * @param {Event} event checkbox event 
     * @param {String} id 서버 ID 
     */
    function changeSelection(event, id) {
        console.debug("selection:", selection)
        selection[id] = event.target.checked
        setSelection({ ...selection })
    }

    /**
     * 선택한 서버들의 tag를 일괄 수정할 편집기 오픈 설정을 한다. 
     * 
     * @param {String} key 수정할 Tag key  
     */
    function showEditor(key) {
        console.debug(key)
        if (Object.values(selection).length === 0) {
            enqueueSnackbar("서버를 선택 하세요 ", { variant: "warning" })
            return
        }

        setEditDialog({ ...editDialog, key: key, open: true })
    }

    /**
     *  전체 서버 목록 재조회 
     */
    function reload(showAlert) {
        setSelection([])
        callApi('/ec2/instances', (result) => {
            context.setServers(result)
            if (showAlert) enqueueSnackbar("조회 되었습니다.", { variant: 'success', autoHideDuration: 2000 })
        }, null,
            (msg) => enqueueSnackbar(msg.msg, { variant: msg.severity }))
    }

    /**
     * 선택 서버 종료 
     */
    function terminateServer() {
        if (Object.values(selection).length === 0) {
            enqueueSnackbar("서버를 선택 하세요 ", { variant: "warning" })
            return
        }

        if (!window.confirm("필요한 내용은 모두 백업 하셨나요? 서버를 삭제하면 더 이상 사용할 수 없습니다!!\n\n삭제하시겠습니까?")) return

        const servers = context.servers.filter(s => selection[s.id])
        if (servers.filter(s => s.profile !== servers[0].profile).length > 0) {
            enqueueSnackbar("다른 AWS 계정은 동시에 삭제할 수 없습니다. 같은 Profile의 서버만 선택 하세요 ", { variant: "warning" })
            return
        }

        deleteApi(`/ec2/terminates`,
            { servers: servers.map(({ id, name, profile, region }) => ({ id, name, profile, region })) }, (result) => {
                enqueueSnackbar(result.msg)
                servers.forEach(server => {
                    fetchServerInfo(server, () => { }, context)
                })
            }, null, (msg) => enqueueSnackbar(msg.msg, { variant: 'error' }))
    }

    /**
     * 선택을 변경한 태그 항목의 선택 값을 변경하고 cookie에 저장해 둔다. - 저장시간 : 1000시간 
     * 
     * @param {Object} item 태그 항목 객체 
     */
    function changeTagSelect(item) {
        tags.filter(i => i.key === item.key).map(i => i.checked = !i.checked)
        setTags([...tags]);
        // 선택 정보는 cookie에 저장해 둔다.
        setCookie("tags", JSON.stringify(tags), { path: "/", expires: new Date(Date.now() + 3600 * 1000) })
    }

    /**
     * 선택을 변경한 속성 항목의 선택 값을 변경하고 cookie에 저장해 둔다. - 저장시간 : 1000시간 
     * 
     * @param {Object} item 속성 항목 객체 
     */
    function changePropSelect(item) {
        properties.filter(i => i.key === item.key).map(i => i.checked = !i.checked)
        setProperties([...properties])
        // 선택 정보는 cookie에 저장해 둔다.
        setCookie("props", JSON.stringify(properties), { path: "/", expires: new Date(Date.now() + 3600 * 1000) })
    }

    /**
     * Browswer의 clipboard에 값을 복사 해 둔다. 
     * 
     * @param {String} value 복사할 값
     */
    function copyToClipboard(value) {
        navigator.clipboard.writeText(value)
        enqueueSnackbar("복사 되었습니다.", { variant: "info", autoHideDuration: 2000 })
    }

    /**
     * Tag일 때는 row에서 tag 정보를 꺼내고, Properties일 때는 기본 정보를 꺼낸다.
     * @param {Object} row TAG 또는 속성 객체
     * @param {String} key key
     * @param {Object} instance row가 TAG 객체이면 서버정보객체, 아니면 null
     * @returns 해당 속성 또는 Tag의 값 이거나 변환된 값
     */
    function getProp(row, key, instance) {
        const value = row[key];
        //console.debug("key: ", key, value)
        if (key === "ip") return (<>
            <Link href={"#"}><ContentCopy sx={{ fontSize: 16, marginX: 1 }} onClick={() => copyToClipboard(row.publicIpAddress)} /></Link>
            {row.publicIpAddress === null ? "" : row.publicIpAddress} /
            <Link href={"#"}><ContentCopy sx={{ fontSize: 16, marginX: 1 }} onClick={() => copyToClipboard(row.privateIpAddress)} /></Link>
            {row.privateIpAddress}
        </>)
        else if (key === "group") return (<>
            {value}
            <Tooltip title="바로가기 URL을 복사 합니다." placement="top">
                <Link href={"#"}><ContentCopy sx={{ fontSize: 16, marginX: 1 }} onClick={() =>
                    copyToClipboard(`https://systems.bwg.co.kr/group/${value}`)} /></Link></Tooltip>

        </>)
        else if (key === "Name") return <span>
            {reloadIcon(instance)}
            {powerIcon(instance, setAlertMsg, context)}
            {value}
            <Tooltip title="바로가기 URL을 복사 합니다." placement="top">
                <Link href={"#"}><ContentCopy sx={{ fontSize: 16, marginX: 1 }} onClick={() =>
                    copyToClipboard(`https://systems.bwg.co.kr/server/${value}`)} /></Link></Tooltip>
        </span>
        else if (key.includes("Time")) return value.replace("T", " ")
        else if (value === undefined || value === null) return <Tooltip title={`'${key}' Tag가 없습니다.'`}><Typography variant="caption" color="GrayText" gutterBottom>N/A</Typography></Tooltip>
        // else if (value === null) return ""
        else if (key === "endpoint" && value.length > 1) return getEndpoint("Endpoint", value, instance);
        else if (key === "health-check" && value.length > 1) return getEndpoint(HC_TITLE, value, instance);
        else if (key === "securityGroup") {
            return value.map((sg, idx) => {
                return <span key={idx}>
                    {idx > 0 ? ", " : ""}
                    {user.authority === 'ADMIN' || (row.accessLevel > 30 && sg.name.toLowerCase().startsWith(row.group.toLowerCase())) // sg.name !== "default" && sg.name !== "sg_finlab_server"
                        ? <Tooltip title="방화벽에 IP를 등록/삭제 합니다." placement="top">
                            <Link href={"#"} onClick={() => editSecurityGroup(row, sg.id)}>{sg.name}</Link>
                        </Tooltip>
                        : <Tooltip title="방화벽에 등록된 목록을 조회 합니다." placement="top">
                            <Link href={"#"} onClick={() => showSecurityGroup(row, sg.id)}>{sg.name}</Link>
                        </Tooltip>
                    }
                </span>
            })
        }
        else if (key === "blockDevice") {
            return <>
                {arvhiveOrRestoreIcon(instance, setAlertMsg, context)}
                {value.map((disk, idx) => <PopupState key={idx} variant="popover" popupId="family-site-popup-menu" disableAutoFocus={false} parentPopupState={null}>
                    {(popupState) => (
                        <React.Fragment>
                            <Link variant="contained" className="me-2" {...bindTrigger(popupState)}>{disk.name}({disk.size}GB)</Link>
                            <Menu {...bindMenu(popupState)}>
                                <MenuItem onClick={() => copyToClipboard(disk.volumeId)}>{disk.volumeId}</MenuItem>
                                <MenuItem>${disk.volumeType === "gp3" ? disk.size * 0.0912 : -1}</MenuItem>
                            </Menu>
                        </React.Fragment>
                    )}
                </PopupState>)}
            </>
        }
        else if (key === "gitlab") return <Tooltip title="Gitlab에 로그인이 되어 있어야 project로 이동이 가능합니다." placement="top">
            <Link target="gitlab" href={`https://git.bwg.co.kr/gitlab/projects/${value}`}>{value}</Link></Tooltip>
        else if (key === "wiki") {
            return <Tooltip title={`Gitlab에 로그인이 되어 있어야 WIKI로 이동이 가능합니다.- ${value}`} placement="top">
                <Link target="wiki" href={`https://git.bwg.co.kr/gitlab${value.startsWith("/") ? value : '/' + value}`}>
                    WIKI
                </Link></Tooltip>
        }
        else if (value.length > 30) return value.substring(0, 30) + "..."
        return value
    }


    function reloadIcon(row) {
        return (<Tooltip title="서버 정보를 조회 합니다." placement="top">
            <Link href={"#"}><ReplayOutlined onClick={() => fetchServerInfo(row, setAlertMsg, context)} /></Link>
        </Tooltip>)
    }



    /**
     * Snackbar 메시지 지정 
     * 
     * @param {Object} msg 
     */
    function setAlertMsg(msg) {
        enqueueSnackbar(msg.msg, { variant: msg.severity, autoHideDuration: msg.autoHideDuration ? msg.autoHideDuration : 5000 })
    }


    /**
     * Endpoint, HC-Endpoint 링크를 누르면 url 목록을 보여 준다. 
     * 
     * @param {String} title 
     * @param {String} value 
     * @param {Object} instance 
     * @returns 변환된 url 목록 
     */
    function getEndpoint(title, value, instance) {
        const endpoints = instance.profile === DEF_PROFILE && title === HC_TITLE
            ? value.replaceAll("{}", instance.privateIpAddress).split(";")
            : value.replaceAll("{}", instance.publicIpAddress).split(";")
        return (
            <PopupState variant="popover" popupId="family-site-popup-menu" disableAutoFocus={false} parentPopupState={null}>
                {(popupState) => (
                    <React.Fragment>
                        {title === HC_TITLE ? getEndpointStatusIcon(instance) : ""}
                        <Link variant="contained" {...bindTrigger(popupState)}>{title}</Link>
                        <Menu {...bindMenu(popupState)}>
                            {endpoints.map((endpoint, index) =>
                                <MenuItem key={index} onClick={() => openPage(popupState, endpoint)}>{endpoint}</MenuItem>)}
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>)
    }
}

/**
 * placeholder에 지정하도록 tagKey에 따라 다른 문자열을 만든다. 
 * 
 * @returns placeholder에 지정할 문자열 
 */
function getPlaceholderText(tagKey) {
    return tagKey === "schedule" ? "start HH:MM stop HH:MM"
        : tagKey === "endpoint" ? "http://{}:8080/;http://{}/pf ';으로 URL 구분하세요, IP대신 {} 사용하세요'"
            : tagKey === "health-check" ? "http://{}:8080/;http://{}/pf ';으로 URL 구분하세요, IP대신 {} 사용하세요'"
                : tagKey === "gitlab" ? "Gitlab project 번호"
                    : tagKey
}


/**
 * 해당 서버의 태그를 수정 편집하는 popup editor, 편집 tag에 바로 위치 시킨다.
 *
 * @param {String} tagKey - 태그 키 이름
 * @param {Object} server - 서버 정보 객체
 * @returns
 */
function TagEditor({ tagKey, server }) {
    const context = useContext(MainContext)
    const [value, setValue] = useState(server.tags[tagKey])
    const { enqueueSnackbar } = useSnackbar();
    const tooltip = tagKey === "schedule" ? "스케줄 설정"
        : tagKey === "endpoint" ? "엔드포인트 설정"
            : tagKey === "health-check" ? "Health Check 설정"
                : tagKey === "gitlab" ? "권한 관리 Gitlab 설정"
                    : ''
    useEffect(() => {
        //console.debug("[TagEditor.useEffect] ", value, server.tags[tagKey])
        if (value !== server.tags[tagKey]) setValue(server.tags[tagKey])
    }, [tagKey])

    const width = tagKey === "description" || tagKey === "endpoint" || tagKey === "health-check" ? "500px" : "300px"
    return (
        <PopupState variant="popover" popupId="family-site-popup-menu" disableAutoFocus={true} parentPopupState={null}>
            {(popupState) => (
                <React.Fragment>
                    <Tooltip title={tooltip} placement="top">
                        <Link variant="contained" {...bindTrigger(popupState)}>
                            <ModeEdit sx={{ fontSize: 16, marginX: 1 }} />
                        </Link>
                    </Tooltip>
                    <Menu {...bindMenu(popupState)}>
                        <Container sx={{ width: width }} className="row">
                            <Input aria-label={tagKey} multiline value={value} sx={{ width: '100%' }}
                                onChange={(e) => setValue(e.target.value)} placeholder={getPlaceholderText(tagKey)} />
                            <Button onClick={saveTag} disabled={value === server.tags[tagKey]}>저장</Button>
                        </Container>
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    );

    /**
     * 저장
     */
    function saveTag() {
        if (value === server.tags[tagKey]) return

        saveApi(`/ec2/${server.id}/tag/${tagKey}/change`, "PUT",
            { value: value, profile: server.profile, gitlab: server.gitlab, region: server.region }, (result) => {
                console.debug("[Ec2Admin.saveTag] tag change all : ", result)
                enqueueSnackbar("저장 되었습니다. ", { variant: "success" })
                fetchServerInfo(server, () => { }, context)
            }, (msg) => { enqueueSnackbar(msg.msg, { variant: "error" }) }
        )
    }
}

export function arvhiveOrRestoreIcon(instance, setAlertMsg, context) {
    if (instance.state === "stopped" && instance.blockDevice.length > 0 && !instance.schedule.includes("start"))
        return (<Tooltip title="서버의 Disk를 Snapshot 만들고 Volume을 삭제 합니다." placement="top">
            <Link href={"#"}><ArchiveOutlined onClick={() => archiveServer(instance, setAlertMsg, context)} className="ms-1 me-2" /></Link>
        </Tooltip>)
    else if (instance.state === "stopped" && instance.blockDevice.length === 0)
        return (<Tooltip title="서버의 Snapshot으로 Volume을 만들고 추가 합니다." placement="top">
            <Link href={"#"}><UnarchiveOutlined onClick={() => restoreServer(instance, setAlertMsg, context)} className="ms-1 me-2" /></Link>
        </Tooltip>)
    else if (instance.blockDevice.length >= 1)
        return (<Tooltip title="서버를 정지 후 사용 가능합니다." placement="top">
            <ArchiveOutlined className="ms-1 me-2" />
        </Tooltip>)

}


/**
 * instance의 volume들의 snapshot을 만들고 volume 들을 삭제 한다.
 * @param instance ec2 instance 객체
 * @param setAlertMsg 일림 메시지 함수
 * @param context MainContext 객체
 */
function archiveServer(instance, setAlertMsg, context) {
    if (!confirm("서버를 더이상 사용하지 않는 경우 사용하는 옵션입니다. 진행하시겠습니까? ")) return;

    const uri = `/ec2/instance/${instance.id}/detachVolumes`

    instance.blockDevice.map((device) => {
        const param = {
            profile: instance.profile,
            gitlab: instance.gitlab,
            volumeId: device.volumeId,
            name: device.name,
            region: instance.region
        };
        saveApi(uri, "POST", param, (msg) => {
            setAlertMsg({ severity: "success", msg: `${msg.msg}` })
            fetchServerInfo(instance, null, context)
        }, (msg) => { setAlertMsg(msg) })
    })
}

/**
 * snapshot 으로 volume을 만들고 ec2에 연결한다.
 * @param instance ec2 instance 객체
 * @param setAlertMsg
 * @param context
 */
function restoreServer(instance, setAlertMsg, context) {
    if (!confirm(`snapshot 으로 Volume을 복구 합니다`)) return;

    const uri = `/ec2/instance/${instance.id}/attachVolumes`

    const param = {
        profile: instance.profile,
        gitlab: instance.gitlab,
        region: instance.region
    };
    saveApi(uri, "POST", param, (msg) => {
        setAlertMsg({ severity: "success", msg: `${msg}` })
        fetchServerInfo(instance, null, context)
    }, (msg) => { setAlertMsg(msg) })
}