import {DeleteOutlined} from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import {useSnackbar} from "notistack";
import React, {useState} from "react";
import {callApi, deleteApi, saveApi} from "../App";

const PORT = "port";
const CIDR = "cidr";
const DESC = "desc";
export default function Ec2SecurityGroupEditor(props) {
    const { enqueueSnackbar } = useSnackbar();
    const instanceId = props.securityGroup.instance.id
    const profile = props.securityGroup.instance.profile
    const region = props.securityGroup.instance.region
    const sgId = props.securityGroup.securityGroup.id
    const gitlab = props.securityGroup.instance.gitlab
    const [sgRule, setSgRule] = useState({})

    return (
        <Dialog open={true} onClose={() => props.setSecurityGroup({})}>
            <DialogTitle>보안 그룹 변경</DialogTitle>

            <DialogContent>
                <TextField
                    id="port"
                    label="Port"
                    value={sgRule[PORT]}
                    placeholder={"특정 포트(80) 또는 0"}
                    variant="standard"
                    onChange={(e) => handleInputChange(PORT, e)}
                />
                <TextField
                    id="IP"
                    label="IP"
                    value={sgRule[CIDR]}
                    placeholder={"123.123.123.123/32"}
                    variant="standard"
                    onChange={(e) => handleInputChange(CIDR, e)}
                />
                <TextField
                    id="description"
                    label="설명"
                    value={sgRule[DESC]}
                    placeholder={"규칙에 대한 설명..."}
                    variant="standard"
                    onChange={(e) => handleInputChange(DESC, e)}
                />
                <Table>
                    <TableBody>
                        {props.securityGroup.securityGroup.permissions.map(rule => {
                            const port = rule.fromPort === 0 || rule.fromPort === null ? "All Port(0)" : rule.fromPort
                            return rule.ranges.map(ip =>
                                <TableRow>
                                    <TableCell>{port}</TableCell>
                                    <TableCell>{ip.cidr}</TableCell>
                                    <TableCell>{ip.desc}</TableCell>
                                    <TableCell>
                                        <Tooltip title="보안 그룹 규칙을 삭제합니다." placement="top">
                                            <DeleteOutlined onClick={() => deleteRule(rule, ip)}/>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </DialogContent>

            <DialogActions>
                <Button variant="primary" onClick={addNewRule} title={`입력한 값으로 정보를 변경 합니다.`}>
                    추가
                </Button>
                <Button variant="secondary" onClick={() => props.setSecurityGroup({})} title="팝업 창을 닫습니다.">
                    닫기
                </Button>
            </DialogActions>
        </Dialog>
    );

    function handleInputChange(key, e) {
        sgRule[key] = e.target.value
        setSgRule({...sgRule})
    }

    function addNewRule() {
        let hasError = false;
        if (sgRule[PORT] === undefined || sgRule[PORT].toString().trim().length === 0 || isNaN(sgRule[PORT] || sgRule[PORT] === '0')) {
            enqueueSnackbar('사용하려는 포트 값을 입력 하세요.', {variant: "error"})
            hasError = true
        }
        if(sgRule[CIDR] === undefined || sgRule[CIDR].toString().trim().length === 0 ){
            enqueueSnackbar('접속하는 곳의 Public IP를 입력 하세요', {variant: "error"})
            hasError = true
        }
        if (!isCIDRValid(sgRule[CIDR])) {
            enqueueSnackbar('올바른 IP형식이 아닙니다. "1.1.1.1/32" 형식으로 입력 하세요', {variant: "error"})
            hasError = true
        }
        if( hasError ) return ;

        if( sgRule[PORT] === '0' && sgRule[CIDR].includes("0.0.0.0")){
            enqueueSnackbar('Port와 IP모두 개방할 수 없습니다. 포트 또는 IP를 지정하세요 !!',{variant: 'error'})
            return 
        }
        
        console.debug(`sg add : ${sgRule}`)
        //sgRule["port"] = parseInt(sgRule["port"])
        const uri = `/ec2/${instanceId}/securityGroup/${sgId}/add?profile=${profile}&gitlab=${gitlab}&region=${region}`
        saveApi(uri, 'POST', sgRule, (json) => {
            console.debug(`response json : ${json}`)
            refresh('success', '보안 그룹 규칙이 추가 되었습니다.');
        }, (msg) => {enqueueSnackbar(msg.msg, { variant: "error" })}) 
    
    }

    function deleteRule(rule, ip) {
        if( window.confirm(`${rule.fromPort===null || rule.fromPort === 0 ? "모든포트" : rule.fromPort} 사용 ${ip.cidr}(${ip.desc}) 규칙을 삭제하시겠습니까?`))
        console.debug(`${rule}, ${ip}`);
        const sgRule = {port : rule.fromPort, cidr: ip.cidr, desc: ip.desc}

        const uri = `/ec2/${instanceId}/securityGroup/${sgId}/delete?profile=${profile}&gitlab=${gitlab}&region=${region}`
        deleteApi(uri, sgRule, () => {
            refresh('success', '보안 그룹 규칙이 삭제 되었습니다.');
        }, "보안그룹", (msg) => {enqueueSnackbar(msg.msg, {variant: msg.severity})})
    }

    function refresh(severity,msg){
        //setAlertMsg({severity: severity, msg: msg});
        enqueueSnackbar(msg, {variant: severity})
        const uri = `/ec2/${instanceId}/securityGroup/${sgId}/fetch?profile=${profile}&gitlab=${gitlab}&region=${region}`
        callApi(uri, (res) =>{
            console.debug("security group info : ", res)
            res["instanceId"] = instanceId;
            res["profile"] = profile;
            // props.setSecurityGroup(res);
            props.setSecurityGroup({securityGroup:res, instance: props.securityGroup.instance, manageSecurityGroup: false});
        }, null, (msg) => enqueueSnackbar(msg.msg, {variant: msg.severity}))
    }
}

export function isCIDRValid(cidr) {
    // CIDR 형식을 IP 주소와 서브넷 마스크로 분리합니다.
    const parts = cidr.split('/');
    if (parts.length !== 2) {
        return false; // 올바른 CIDR 형식이 아닙니다.
    }

    const ipAddress = parts[0];
    const subnetMask = parseInt(parts[1], 10);

    // IP 주소의 유효성을 확인합니다.
    const ipAddressParts = ipAddress.split('.');
    if (ipAddressParts.length !== 4) {
        return false; // 올바른 IP 주소 형식이 아닙니다.
    }

    for (const octet of ipAddressParts) {
        const octetValue = parseInt(octet, 10);
        if (isNaN(octetValue) || octetValue < 0 || octetValue > 255) {
            return false; // 유효하지 않은 IP 주소입니다.
        }
    }

    // 서브넷 마스크의 유효성을 확인합니다.
    return !(isNaN(subnetMask) || subnetMask < 0 || subnetMask > 32);


}