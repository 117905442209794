import React, {createContext, useEffect, useState} from 'react';
import Main from './Main';
import {SnackbarProvider, useSnackbar} from 'notistack';

export const UserContext = createContext(undefined);

function App() {
    const [user, setUser] = useState({})
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        // 없으면 두번 조회 함. 최초 한번, 변경되고 또한번 조회함. 이미 조회한 경우 다시 조회할 필요가 없어 추가 함.
        if (user.name !== undefined) return;

        console.debug("[App.useEffect]", user);
        callApi("/user", (user) => {
            console.debug("[App.useEffect] setUser", user);
            setUser(user)
            if (user.name !== "" && user.authority !== "ADMIN"){
                //enqueueSnackbar("권한을 조회 중 입니다. 조회가 완료되면 서버 권한설정이 변경 됩니다.", {variant: 'info'})
                callApi("/user/authority/fetch", (user) => {
                    console.debug("[App./user/authority/fetch] setUser", user);
                    setUser(user)
                })
            }
            // 로그인 팝업에서 로그인 후 사용자 세션이 수정되면 로그인 창을 닫는다.
            if (window.opener) window.close()
        }, { name: "Guest", menu: [], isAdmin: false }, null,
            (msg) => {alert(msg.msg)})
    }, [user])

    return (
        <UserContext.Provider value={user}>
            <SnackbarProvider maxSnack={5} autoHideDuration={10000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Main />
            </SnackbarProvider>
        </UserContext.Provider>
    );
}


export function callApi(url, setter, defaultValue, errorMsg) {
    fetch(url)
        .then(response => {
            console.debug(`response : ${response}`)
            if (response.redirected === true) {
                window.location.href = "/"
            }
            return response
        })
        .then(handleResponse)
        .then(result => {
            console.debug(`[callApi-${url}] result status : ${result.status}`)
            if (result.status >= 400) {
                throw result.message
            }
            if (result.status === undefined) setter(result)
            else { // error 이면 status가 있다. 
                console.error(result)
                console.error(result.trace)
                const msg = result.path + " : " + result.message + (result.trace ? ":\n\n콘솔에서 trace를 확인하세요" : "")
                setter(msg)
            }
        }).catch(e => {
            // login이 필요한 경우 redirect 되면서 'Failed to fetch' 발생 
            console.error(`fetch error : ${e.stack}`);
            if( errorMsg ) errorMsg({msg: `Fetch error : ${e}`, severity: 'error'})
            if (defaultValue) setter(defaultValue)
            if (e.message === 'Failed to fetch') {
                //alert(`Login popup을 열고 로그인을 시도 합니다. \n - error : ${e}`)
                if( errorMsg ) errorMsg({msg: '로그인이 필요하여 새 창을 열었습니다. 작업을 다시 시도 하세요!!!', severity: 'info'})
                window.open("/oauth2/authorization/keycloak")
            }
        })

    function handleResponse(response) {
        console.debug(`response.type : ${response.type}`);
        console.debug(`response.redirected : ${response.redirected}`);
        console.debug(`response.status : ${response.status}`);

        // Simplified conditionals
        if (response.type === "opaqueredirect") {
            alert("You need login");
            window.open("/oauth2/authorization/keycloak");
            return Promise.reject('Redirected: Login required');
        } else if (response.redirected) {
            // window.location.href = "/";
            window.open("/oauth2/authorization/keycloak")
            return Promise.reject('Redirected: Root');
        }

        return response.json();
    }
}

export function saveApi(uri, method, data, callback, errorMsg) {
    fetch(uri, {
        method: method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        console.debug(`response : ${response.status} , ${response.type}`)
        if (response.redirected === true) {
            window.location.href = "/"
        }
        return response
    }).then((response) => response.json()).then((json) => {
        if (json.status >= 400) {
            throw json.message
        }
        callback(json)
    }).catch((error) => {
        console.error(error);
        //alert(`저장에 실패 했습니다. ${error}`)
        if(errorMsg) errorMsg({msg:`${error}`, severity: 'error', data: data})
        if (error.message === 'Failed to fetch') {
            window.open("/oauth2/authorization/keycloak")
            errorMsg({msg: '로그인이 필요하여 새 창을 열었습니다. 작업을 다시 시도 하세요!!!', severity: 'info'})
        }
    })
}

export function deleteApi(url, data, callback, key, errorMsg) {
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then((response) => response.json()).then((json) => {
        if (json.status === undefined) {
            if (callback) callback(json)
        } else {
            console.error(json);
            errorMsg({msg:`삭제 실패 했습니다. ${json.message}`, severity: 'error'})
        }
    }).catch((error) => {
        console.error(error);
        if (error.message === 'Failed to fetch') {
            window.open("/oauth2/authorization/keycloak")
            errorMsg({msg: '로그인이 필요하여 새 창을 열었습니다. 작업을 다시 시도 하세요!!!', severity: 'info'})
        }
        else if( key != null ) errorMsg({msg:`${key} - 삭제 실패 했습니다. ${error}`})
        else errorMsg({msg:`삭제 실패 했습니다. ${error}`, severity: 'error'})
    })
}
export default App;
